<template>
<!-- 客户开箱不良 -->
    <index v-if="JSON.stringify($route.query) === '{}'" />
    <router-view  v-else/>
</template>
<script>
import index from './comp'
export default {
  components: { index }
}
</script>
