<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead
          compType="customer-unpack"
          @search="search"
          @reset="search"
          @saleArea="saleArea"
        />
        <tableComp
          v-loading="loading"
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="rate" label="开箱不良率">
            <template v-slot="scope">
              {{ scope.row.rate + '%' }}
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            fixed="right"
          >
            <template v-slot="scope">
              <btnComp
                btnType="text"
                @click="goDetail(scope.row, 'customer-unpack-detail')"
                >发货明细</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
  </div>
</template>
<script>
import searchHead from '@/views/data-center/components/searchHead'
import { formatDate, diffDate } from '@/utils/common'
import { customerUnpackList } from '@/api'
export default {
  components: {
    searchHead
  },
  data () {
    return {
      loading: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      isShow: false,
      customerName: '',
      beginMonth: '',
      endMonth: '',
      startTime: '',
      endTime: '',
      timeType: 1,
      orderType: 1,
      level: '',
      cityId: '',
      storeCountCondition: '',
      tableData: [],
      theadName: [
        { slot: 'rate' },
        { prop: 'customerName', label: '客户' },
        { prop: 'storeCount', label: '商家数量' },
        { prop: 'orderCount', label: '订单数量' },
        { prop: 'deliveryCount', label: '发货数量(台)' },
        { prop: 'repairCount', label: '报修数量(台)' },
        { prop: 'packCount', label: '开箱不良(台)' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    // 缺省默认6个月
    /* this.endTime = formatDate(new Date())
    this.startTime = diffDate(-6, 'days', true)
    this.date = [diffDate(-6, 'days'), new Date()]
    this.getList() */
  },
  methods: {
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.level = level
      this.cityId = cityId
      this.endTime = formatDate(new Date())
      this.startTime = diffDate(-6, 'days', true)
      this.date = [diffDate(-6, 'days'), new Date()]
      this.getList()
    },
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        level: this.level,
        cityId: this.cityId,
        customerName: this.customerName,
        storeCountCondition: this.storeCountCondition,
        timeType: this.timeType,
        orderType: this.orderType,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      customerUnpackList(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    goDetail (row, name) {
      const { customerName } = row
      const route = this.$router.resolve({
        name,
        query: {
          customerName,
          orderType: this.orderType,
          timeType: this.timeType,
          startTime: this.startTime,
          endTime: this.endTime
        }
      })
      // 重新打开新的页面
      window.open(route.href, '_blank')
    },
    close () {
      this.isShow = false
    },
    search (val) {
      this.curPage = 1
      const {
        level,
        cityId,
        storeCountCondition,
        customerName,
        timeType,
        orderType,
        startTime,
        endTime
      } = val
      this.level = level
      this.cityId = cityId
      this.storeCountCondition = storeCountCondition
      this.customerName = customerName
      this.timeType = timeType
      this.orderType = orderType
      this.startTime = startTime
      this.endTime = endTime
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped></style>
